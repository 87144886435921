import type { DependencyList } from 'react';
import { useEffect, useState } from 'react';
import type { Observable } from 'rxjs';
import { container } from 'src/StaticContainer';

const log = container.get('Logger').getSubLogger({ name: 'useObservableValue' });

export function useObservableValue<T>(obs: () => Observable<T>, initialState: T, deps: DependencyList): T;
export function useObservableValue<T>(obs: Observable<T> | undefined, initialState: T): T;

export function useObservableValue<T>(
    obs: (() => Observable<T>) | Observable<T> | undefined,
    initialState: T,
    deps?: DependencyList,
): T {
    const [value, setValue] = useState<T>(initialState);
    const obsDeps = typeof obs === 'function' ? [] : [obs];

    useEffect(
        () => {
            let obsinternal = obs;
            if (obsinternal && typeof obsinternal === 'function') obsinternal = obsinternal();
            const sub = obsinternal?.subscribe({
                next: setValue,
                complete: () => {
                    log.trace({ message: 'useObservableValue subscription completed' });
                },
                error: (e) => {
                    log.error({ message: 'useObservableValue subscription received an error:', error: e });
                    throw e;
                },
            });
            return () => {
                log.trace({ message: 'useObservableValue unsubscribing' });
                sub?.unsubscribe();
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps ?? obsDeps,
    );
    return value;
}
