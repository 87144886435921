import { t } from 'i18next';
import localforage from 'localforage';
import { all, put, takeLatest } from 'redux-saga/effects';
import { container } from 'src/StaticContainer';
import type { LocatePreferences } from 'src/contracts/user-preference';
import type { ChangeLocateWarningPreferencesAction } from 'src/store/actions/locates/locatePreferences';
import { setLocatePreferences } from 'src/store/actions/locates/locatePreferences';
import { setSnackbar } from 'src/store/actions/ui';
import { select } from 'src/store/effects';
import { LOCATE_PREFERENCES_KEY } from 'src/store/types/locates';

const log = container.get('Logger').getSubLogger({ name: 'locates-sagas' });

export function* handleChangeWarningPreference(action: ChangeLocateWarningPreferencesAction) {
    try {
        const oldLocatePreferences: LocatePreferences = yield select((state) => state.locates.locatePreferences);

        const updatedLocatePreferences: LocatePreferences = {
            ...oldLocatePreferences,
            warningPreferences: {
                ...oldLocatePreferences.warningPreferences,
                ...action.warningPreferences,
            },
        };

        yield put(setLocatePreferences(updatedLocatePreferences));

        if (action.forever) {
            yield localforage.setItem(LOCATE_PREFERENCES_KEY, updatedLocatePreferences);
        }
    } catch (e) {
        yield put(
            setSnackbar(t('widgets::lab::preferences::update-error', 'Updating locate warning preferences failed'), {
                variant: 'error',
            }),
        );

        if (e instanceof Error) {
            log.error({ message: 'Updating locate warning preferences failed', error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    }
}

export function* locatesPreferencesSagas() {
    yield all([
        takeLatest('locatePreferences::changeLocateWarningPreferences', handleChangeWarningPreference),
        // takeLatest('locates::changeAutoAccept', handleChangeAutoAccept),
    ]);
}
