import type { LDFlagChangeset } from 'launchdarkly-js-sdk-common';
import type { EventChannel } from 'redux-saga';
import { eventChannel } from 'redux-saga';
import { getContext, take, takeEvery, all, put } from 'redux-saga/effects';
import { container } from 'src/StaticContainer';
import { allFlags } from 'src/lib/featureFlags/allFlags';
import type { SetUserDetailsAction } from 'src/store/actions/auth';
import { setFeatureFlags } from 'src/store/actions/featureFlags';
import type { SagaContext } from 'src/store/types';

const log = container.get('Logger').getSubLogger({ name: 'sagas:featureFlags' });

type FlagSet = Record</* key */ string, /* value */ unknown>;
interface FlagUpdateMessage {
    type: 'flagsUpdated';
    changeset: FlagSet;
}

function* setupLaunchDarkly() {
    const launchDarklyClient: SagaContext['launchDarklyClient'] = yield getContext('launchDarklyClient');

    const channel: EventChannel<FlagUpdateMessage> = eventChannel((emit: (input: FlagUpdateMessage) => void) => {
        const handleChange = (ldFlagChangeset: LDFlagChangeset) => {
            const changeset = Object.entries(ldFlagChangeset).reduce((allFlagsUpdated, [key, { current }]) => {
                return {
                    ...allFlagsUpdated,
                    [key]: current,
                };
            }, {} as FlagSet);

            emit({
                type: 'flagsUpdated',
                changeset,
            });
        };

        launchDarklyClient.waitUntilReady().then(() => {
            launchDarklyClient.on('change', handleChange);

            const initialFlags: FlagSet = Object.values(allFlags).reduce((flags, flag) => {
                return {
                    ...flags,
                    [flag.key]: launchDarklyClient.variation(flag.key, flag.defaultValue),
                };
            }, {} as FlagSet);

            log.debug({ message: 'Initial flags:', initialFlags });

            emit({ type: 'flagsUpdated', changeset: initialFlags });
        });

        return () => {
            launchDarklyClient.off('change', handleChange);
        };
    });

    while (true) {
        const ldAction: FlagUpdateMessage = yield take(channel);

        log.debug({ message: 'Flags updated:', changeset: ldAction.changeset });

        yield put(setFeatureFlags(ldAction.changeset));
    }
}

function* onUserSet(action: SetUserDetailsAction) {
    const launchDarklyClient: SagaContext['launchDarklyClient'] = yield getContext('launchDarklyClient');

    const { userDetails } = action;
    if (!userDetails) return;

    log.debug({ message: 'Identifying user:', userDetails });

    yield launchDarklyClient.waitUntilReady();

    launchDarklyClient.identify({
        key: userDetails.id,
        kind: 'user',
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        name: userDetails.email,
        isDeveloper: userDetails.isDeveloper,
        isSuperuser: userDetails.isSuperuser,
        custom: userDetails,
        anonymous: false,
    });
}

export function* featureFlagsSagas() {
    yield all([setupLaunchDarkly(), takeEvery('user-details::set', onUserSet)]);
}
