import {
    getDefaultAlphaLensWidget,
    getDefaultEventsWidget,
    getDefaultNewsWidget,
    getDefaultScreenerWidget,
    getDefaultStrategyWidget,
    getDefaultTimeSeriesWidget,
} from './defaults';
import { call, getContext, select } from 'redux-saga/effects';
import { getDefaultUniverseQuery } from 'src/components/menus/Universe/queries';
import type { ConcreteUniverse } from 'src/contracts/universe';
import type {
    AccountsWidgetModel,
    AggregatedPositionsWidgetModel,
    ExecutionsWidgetModel,
    IfThenBuilderWidgetModel,
    SearchAlphaWidgetModel,
    LocatesWidgetModel,
    MessagesWidgetModel,
    OrderBlotterWidgetModel,
    OrderEntryWidgetModel,
    OrdersWidgetModel,
    PositionsWidgetModel,
    TableWidgetModel,
    WidgetCreationInfo,
    SimpleOrderEntryWidgetModel,
    Widget,
    WidgetTab,
} from 'src/contracts/workspace';
import { defaultQuantityStep } from 'src/routes/widgets/LocatesWidget/formatting';
import { type RootState } from 'src/store';
import { createDefaultSearchAlphaWidget } from 'src/store/defaults/widget';
import type { SagaContext } from 'src/store/types';
import { UnreachableCaseError } from 'ts-essentials';

export function* getWidgetFromCreationInfo(creationInfo: WidgetCreationInfo): Generator<any, Widget, any> {
    const channelId = 'channelId' in creationInfo ? creationInfo.channelId : null;

    switch (creationInfo.creationType) {
        case 'cloned': {
            const widgetTab: WidgetTab = yield select(
                (store: RootState) => store.tab.byId[creationInfo.baseWidgetTabId],
            );

            return widgetTab.widget;
        }

        case 'new-alpha-lens': {
            const widget = yield* getDefaultAlphaLensWidget(creationInfo.defaultSymbol);
            return widget;
        }

        case 'new-time-series': {
            const widget = yield* getDefaultTimeSeriesWidget(creationInfo.defaultSymbol);
            return widget;
        }

        case 'new-strategy': {
            const widget = yield* getDefaultStrategyWidget(
                'strategy',
                creationInfo.strategies,
                creationInfo.defaultSymbol,
            );

            return widget;
        }

        case 'from-widget-default': {
            const widgetType = creationInfo.widgetType;
            switch (widgetType) {
                case 'alpha-lens': {
                    return yield* getDefaultAlphaLensWidget();
                }
                case 'time-series': {
                    return yield* getDefaultTimeSeriesWidget();
                }
                case 'strategy': {
                    return yield* getDefaultStrategyWidget('strategy', []);
                }
                case 'watchlist': {
                    return yield* getDefaultStrategyWidget('watchlist', []);
                }
                case 'news-and-events': {
                    return yield* getDefaultNewsWidget();
                }
                case 'events': {
                    return yield* getDefaultEventsWidget();
                }
                case 'screener': {
                    return yield* getDefaultScreenerWidget();
                }
                case 'search-alpha': {
                    const container: NonNullable<SagaContext['container']> = yield getContext('container');
                    const queryClient = container.get('QueryClient');
                    const defaultUniverse: ConcreteUniverse | undefined = yield call(() =>
                        queryClient.fetchUserQuery(getDefaultUniverseQuery()),
                    );
                    const widget: SearchAlphaWidgetModel = createDefaultSearchAlphaWidget({
                        universe: defaultUniverse ?? null,
                    });

                    return widget;
                }
                case 'order-blotter': {
                    const widget: OrderBlotterWidgetModel = {
                        type: 'order-blotter',
                        channelId,
                        accountId: null,
                        statusFilter: null,
                        viewData: null,
                        formattingSettings: null,
                        tickerFilter: null,
                    };

                    return widget;
                }

                case 'accounts': {
                    const widget: AccountsWidgetModel = {
                        type: 'accounts',
                        channelId,
                        columnTemplate: null,
                        platforms: null,
                        accountIds: null,
                        userIds: null,
                        filter: null,
                    };

                    return widget;
                }
                case 'aggregated-positions': {
                    const widget: AggregatedPositionsWidgetModel = {
                        type: 'aggregated-positions',
                        channelId,
                        columnTemplate: null,
                        platforms: null,
                        accountIds: null,
                        userIds: null,
                        aggregateBy: 'user',
                        filter: null,
                    };

                    return widget;
                }
                case 'if-then-builder': {
                    const widget: IfThenBuilderWidgetModel = {
                        type: 'if-then-builder',
                        channelId,
                        strategy: null,
                        universe: null,
                    };

                    return widget;
                }
                case 'simple-order-entry': {
                    const widget: SimpleOrderEntryWidgetModel = {
                        type: 'simple-order-entry',
                        channelId,
                        accountId: null,
                        skipTradeConfirmation: false,
                        symbol: null,
                    };

                    return widget;
                }
                case 'order-entry': {
                    const widget: OrderEntryWidgetModel = {
                        type: 'order-entry',
                        channelId,
                        accountId: null,
                        skipTradeConfirmation: false,
                        symbol: null,
                    };

                    return widget;
                }
                case 'messages': {
                    const widget: MessagesWidgetModel = {
                        type: 'messages',
                        channelId,
                        accountId: null,
                        statusFilter: null,
                        viewData: null,
                        formattingSettings: null,
                    };

                    return widget;
                }
                case 'positions': {
                    const widget: PositionsWidgetModel = {
                        type: 'positions',
                        channelId,
                        columnTemplate: null,
                        platforms: null,
                        accountIds: null,
                        userIds: null,
                        filter: null,
                    };

                    return widget;
                }

                case 'orders': {
                    const widget: OrdersWidgetModel = {
                        type: 'orders',
                        channelId,
                        columnTemplate: null,
                        platforms: null,
                        accountIds: null,
                        userIds: null,
                        filter: null,
                    };

                    return widget;
                }

                case 'table': {
                    const widget: TableWidgetModel = {
                        type: 'table',
                        channelId,
                        table: null,
                        class: null,
                        filter: null,
                        viewData: null,
                    };
                    return widget;
                }
                case 'executions': {
                    const widget: ExecutionsWidgetModel = {
                        type: 'executions',
                        channelId,
                        columnTemplate: null,
                        platforms: null,
                        accountIds: null,
                        userIds: null,
                        filter: null,
                    };

                    return widget;
                }

                case 'locates': {
                    const widget: LocatesWidgetModel = {
                        type: 'locates',
                        channelId,
                        accountIds: null,
                        filter: null,
                        isQuantityKilo: false,
                        isSoundAlertEnabled: false,
                        selectedTab: 'all',
                        isUserOnly: true,
                        formData: {
                            accountId: null,
                            symbol: '',
                            quantity: defaultQuantityStep,
                            providerId: null,
                        },
                        ordersTableData: {
                            viewData: null,
                            formattingSettings: null,
                        },
                        costInfoTableData: {
                            columnTemplate: null,
                        },
                        quickSendButtons: [],
                    };

                    return widget;
                }
                default:
                    throw new UnreachableCaseError(widgetType);
            }
        }
    }
}
