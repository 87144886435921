import 'init/1-polyfills';
import 'init/2-containerWeb';
import 'init/3-prelude';
import { container } from 'src/StaticContainer';
import { createReactContainerAndRunStore } from 'src/lib/createReactContainerAndRunStore';
import { launchDarklyBrowserModule } from 'src/store/modules/launchDarklyBrowserModule';
import { localStoreModule } from 'src/store/modules/localStoreModule';

container.load(launchDarklyBrowserModule);
window.reactContainer = createReactContainerAndRunStore(localStoreModule);
