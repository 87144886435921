import type { ConsoleLogAction } from '../actions/keyboardShortcuts';
import { all, takeLatest } from 'redux-saga/effects';
import { container } from 'src/StaticContainer';

const log = container.get('Logger').getSubLogger({ name: 'keyboardShortcuts-sagas' });

// this is just an example of a keyboardShortcut saga
export function* onConsoleLog(action: ConsoleLogAction) {
    try {
        log.info({ message: action.message });
    } catch (error) {
        if (error instanceof Error) {
            log.error({ message: error.message, error: error });
        } else {
            log.fatal({ message: 'Unknown exception', details: error });
        }
    }
}

export function* keyboardShortcutsSagas() {
    yield all([takeLatest('keyboardShortcuts::log', onConsoleLog)]);
}
