import type { PermissionType } from '@thinkalpha/common/contracts/permissions.js';
import { createInstance } from 'src/api';
import type { BulkAccountRequestPayload, ConcreteAccount } from 'src/contracts/account';
import { appConfig } from 'src/lib/config';

const axios = createInstance({ baseURL: appConfig.tradingApi });

export async function getUserAccounts(access: PermissionType = 'reader'): Promise<ConcreteAccount[]> {
    return axios.get<ConcreteAccount[]>('/accounts', { params: { access } }).then((x) => x.data);
}

export async function getAccountById(id: string): Promise<ConcreteAccount> {
    const resp = await axios.get<ConcreteAccount>(`/accounts/${id}`);
    return resp.data;
}

export async function getAccountsById(ids: string[]): Promise<ConcreteAccount[]> {
    const resp = await axios.post<ConcreteAccount[]>('/accounts/bulk-requests', {
        accountIds: ids,
    } satisfies BulkAccountRequestPayload);
    return resp.data;
}
