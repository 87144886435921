import { type TypedContainerModule } from '@thinkalpha/common/ioc/TypedContainer.js';
import { initReactContainer } from 'src/ReactContainer';
import { setStoreForApi } from 'src/api';
import { beginBootstrap } from 'src/store/actions/app';
import type { StaticBindings } from 'src/types/bindings';

export const createReactContainerAndRunStore = (reduxContainerModule: TypedContainerModule<StaticBindings>) => {
    // Create the container
    const container = initReactContainer();
    container.load(reduxContainerModule);

    const store = container.get('Store');
    window.store = store;

    // Some store-related services and values are instantiated here after the store is created
    setStoreForApi(store);

    // Instantiate select singletons after store creation
    container.get('History');
    container.get('SyncInternalConfigurationsService');
    container.get('SyncToExternalsService');

    if (!APP_ELECTRON) {
        store.run();
        const state = store.getState();
        if (!state.app.bootstrapped) {
            store.dispatch(beginBootstrap());
        }
    }

    return container;
};
