import { updateTabName } from '../actions/tab';
import type { RefetchStoredUniverseAction } from '../actions/universe';
import { setUniverse } from '../actions/universe';
import type { ChangeSelectedUniverseAction } from '../actions/widgetAndChannel';
import { setSelectedUniverse } from '../actions/widgetAndChannel';
import type { SagaContext, WidgetTabViewModel } from '../types';
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { getUniverseById } from 'src/components/menus/Universe/api';
import type { ConcreteUniverse } from 'src/contracts/universe';
import type { StrategyWidgetModel, Widget } from 'src/contracts/workspace';
import type { ThinkAlphaQueryClient } from 'src/lib/config/query-client';

export async function fetchWatchListUniverses(
    widgetTabViewModels: WidgetTabViewModel[],
    queryClient: ThinkAlphaQueryClient,
): Promise<(ConcreteUniverse | undefined)[]> {
    const WatchListWidgets: StrategyWidgetModel[] = widgetTabViewModels.reduce((acc, widgetTabViewModel) => {
        return isStrategyWidgetViewModel(widgetTabViewModel.widget) ? [...acc, widgetTabViewModel.widget] : acc;
    }, []);

    return Promise.all(
        WatchListWidgets.map((widget) => {
            const id = typeof widget.universe === 'string' ? widget.universe : widget.universe?.id;

            if (!id) return;
            return fetchUniverse(id, queryClient);
        }),
    );
}

function isStrategyWidgetViewModel(viewModel: Widget): viewModel is StrategyWidgetModel {
    return viewModel.type === 'watchlist';
}

async function fetchUniverse(universeId: string, queryClient: ThinkAlphaQueryClient) {
    return queryClient.fetchUserQuery({
        queryKey: ['universe', universeId],
        queryFn: () => getUniverseById(universeId),
    });
}

function* changeSelectedUniverse(action: ChangeSelectedUniverseAction) {
    const universeId = typeof action.universe === 'string' ? action.universe : action.universe?.id;
    const container: NonNullable<SagaContext['container']> = yield getContext('container');
    const queryClient = container.get('QueryClient');

    if (universeId) {
        const universe: ConcreteUniverse | undefined = yield select((state) => state.universe.universeById[universeId]);
        if (!universe) {
            const serverUniverse: ConcreteUniverse | undefined = yield call(fetchUniverse, universeId, queryClient);
            if (serverUniverse) {
                yield all([put(setUniverse(serverUniverse)), put(updateTabName(action.tabId, serverUniverse.name))]);
            }
        } else {
            yield put(updateTabName(action.tabId, universe.name));
        }
    } else {
        yield put(updateTabName(action.tabId, ''));
    }
    const { type: _, ...rest } = action;
    yield put(setSelectedUniverse(rest));
}

function* refetchStoredUniverse(action: RefetchStoredUniverseAction) {
    const container: NonNullable<SagaContext['container']> = yield getContext('container');
    const queryClient = container.get('QueryClient');
    const universe: ConcreteUniverse | undefined = yield call(fetchUniverse, action.universeId, queryClient);
    if (!universe) return;
    yield put(setUniverse(universe));
}

export function* universeSagas() {
    yield all([
        takeLatest('changeSelectedUniverse', changeSelectedUniverse),
        takeLatest('refetchStoredUniverse', refetchStoredUniverse),
    ]);
}
