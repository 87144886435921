import type { TourGuideStep } from '@sjmc11/tourguidejs/src/types/TourGuideStep';
import type { ModifiedTourStep, WidgetWithAvailableTour } from 'src/features/onboarding/types';

export type StartTourGuideAction = {
    type: 'tour-guide::start';
    payload: {
        containerId: string;
        widgetType: WidgetWithAvailableTour;
    };
};
export const startTourGuide = (widgetType: WidgetWithAvailableTour, containerId: string): StartTourGuideAction => ({
    type: 'tour-guide::start',
    payload: {
        containerId,
        widgetType,
    },
});
export type ExitTourGuideAction = { type: 'tour-guide::exit' };
export const exitTourGuide = (): ExitTourGuideAction => ({
    type: 'tour-guide::exit',
});

export type SetIsTourGuideActiveAction = { type: 'tour-guide::setIsTourActive'; payload: boolean };
export const setIsTourGuideActive = (value: boolean): SetIsTourGuideActiveAction => ({
    type: 'tour-guide::setIsTourActive',
    payload: value,
});

export type SetActiveTourContainerAction = { type: 'tour-guide::setActiveTourContainer'; payload: string | null };
export const setActiveTourContainer = (value: string | null): SetActiveTourContainerAction => ({
    type: 'tour-guide::setActiveTourContainer',
    payload: value,
});

// Modifying steps triggers the modification saga which calls update to update the stores steps
export type ModifyTourStepsAction = {
    type: 'tour-guide::modifyTourSteps';
    payload: {
        widgetType: WidgetWithAvailableTour;
        containerId: string;
        modifiedSteps: ModifiedTourStep[];
    };
};
export const modifyTourSteps = (
    widgetType: WidgetWithAvailableTour,
    containerId: string,
    modifiedSteps: ModifiedTourStep[],
): ModifyTourStepsAction => ({
    type: 'tour-guide::modifyTourSteps',
    payload: {
        widgetType,
        containerId,
        modifiedSteps,
    },
});

// once steps are modified with component-level scoped context, the store's steps are updated to be used
// when tour is started
export type UpdateTourStepsAction = {
    type: 'tour-guide::updateTourSteps';
    payload: {
        widgetType: WidgetWithAvailableTour;
        containerId: string;
        updatedSteps: TourGuideStep[];
    };
};
export const updateTourSteps = (
    widgetType: WidgetWithAvailableTour,
    containerId: string,
    updatedSteps: TourGuideStep[],
): UpdateTourStepsAction => ({
    type: 'tour-guide::updateTourSteps',
    payload: {
        widgetType,
        containerId,
        updatedSteps,
    },
});

export type UpdateTourStepTargetsAction = {
    type: 'tour-guide::updateTourStepTargets';
    payload: {
        widgetType: WidgetWithAvailableTour;
        containerId: string;
    };
};
export const updateTourStepTargets = (
    widgetType: WidgetWithAvailableTour,
    containerId: string,
): UpdateTourStepTargetsAction => ({
    type: 'tour-guide::updateTourStepTargets',
    payload: {
        widgetType,
        containerId,
    },
});

export type OnboardingAction =
    | StartTourGuideAction
    | SetIsTourGuideActiveAction
    | SetActiveTourContainerAction
    | ModifyTourStepsAction
    | UpdateTourStepTargetsAction
    | UpdateTourStepsAction;
