import { comparePermissions } from '@thinkalpha/common/util/permissions.js';
import type { RouterActions } from 'redux-first-history';
import { CALL_HISTORY_METHOD } from 'redux-first-history';
import { all, put, select, take, takeLatest } from 'redux-saga/effects';
import type { RootState } from 'src/store';
import type { AppAction, VerifyIntentAction } from 'src/store/actions';
import type { CloseActiveModalAction } from 'src/store/actions/overlay';
import { openModal } from 'src/store/actions/overlay';
import type { SaveCurrentWorkspaceAction } from 'src/store/actions/workspace';
import type { RemoteSliceActions } from 'src/store/lib/createRemoteSlice';
import { createRemoteSliceAction } from 'src/store/lib/createRemoteSlice';
import { confirmLeavingWorkspace } from 'src/store/overlays/confirmLeavingWorkspace';

export function* checkIfCurrentWorkspaceNeedsToSave(action: VerifyIntentAction<RouterActions>) {
    const currentState: RootState = yield select();
    if (currentState.router.location?.pathname?.includes('dashboard')) {
        // Prompt user to save workspace if it's dirty. Desktop will always prompt user to save workspace.
        if (currentState.workspace?.currentWorkspace?.isDirty || APP_ELECTRON) {
            // If you cancel, then we don't want to navigate
            const [modalClosed]: [RemoteSliceActions<typeof confirmLeavingWorkspace>['closeModal']] = yield all([
                take(confirmLeavingWorkspace.actionTypes.closeModal),
                put(createRemoteSliceAction(confirmLeavingWorkspace, 'openModal')),
            ]);

            const { payload } = modalClosed;

            if (payload === 'cancel') {
                return;
            }
        }
    }
    yield put({ ...action.originalAction, isIntentConfirmed: true } as AppAction);
}

export function* checkIfCurrentWorkspaceIsTemplate(action: VerifyIntentAction<SaveCurrentWorkspaceAction>) {
    const currentState: RootState = yield select();

    if (
        action.originalAction.saveType !== 'saveAs' &&
        currentState.workspace.currentWorkspace?.isTemplate &&
        comparePermissions(currentState.workspace.currentWorkspace?.permissions?.effectiveAccess, 'writer') >= 0
    ) {
        const [modalClosed]: [CloseActiveModalAction<'cancel' | 'saved'>] = yield all([
            take('close-modal'),
            put(openModal({ type: 'verify-saving-workspace' })),
        ]);

        const { result } = modalClosed;

        if (result === 'cancel') return;
    } else {
        yield put({ ...action.originalAction, isIntentConfirmed: true } as AppAction);
    }
}

type MaybeVerificationAction = AppAction | VerifyIntentAction<AppAction>;

export function* intentVerificationSagas() {
    yield all([
        takeLatest(function (action: MaybeVerificationAction) {
            return (
                action.type === 'verifyIntentAction' &&
                [CALL_HISTORY_METHOD, 'workspace-template-library::open', 'createBlankWorkspaceEvent'].includes(
                    action.originalAction.type,
                )
            );
        }, checkIfCurrentWorkspaceNeedsToSave),

        takeLatest(function (action: MaybeVerificationAction) {
            return action.type === 'verifyIntentAction' && action.originalAction.type === 'saveWorkspaceEvent';
        }, checkIfCurrentWorkspaceIsTemplate),
    ]);
}
