import type { FetchQueryOptions } from '@tanstack/query-core';
import type { WorkspaceConfig } from 'src/contracts/workspace';
import { ONE_HOUR } from 'src/lib/util/timeConstants';
import { getWorkspaceConfig } from 'src/lib/workspaces';

export const getWorkspaceConfigQuery = (): FetchQueryOptions<WorkspaceConfig> => ({
    queryKey: ['workspace-config'],
    queryFn: () => getWorkspaceConfig(),
    staleTime: ONE_HOUR,
});
