import { onLogoutEvent } from '../auth/index';
import type { RouterActions } from 'redux-first-history';
import { LOCATION_CHANGE, CALL_HISTORY_METHOD, push, replace } from 'redux-first-history';
import { all, put, select, takeEvery, getContext, takeLatest, call } from 'redux-saga/effects';
import type { RootState } from 'src/store';
import { setApplicationMenu } from 'src/store/actions/ui';
import type { SagaContext } from 'src/store/types';
import { ApplicationMenu } from 'src/store/types';

function* updateNativeMenus() {
    const pathname: string = yield select((state: RootState) => state.router.location?.pathname ?? '');
    const loggedIn: boolean = yield select((state: RootState) => Boolean(state.auth.accessToken));
    const menus: RootState['ui']['menus'] = yield select((state: RootState) => state.ui.menus);

    if (!loggedIn) {
        if (pathname === '/login' && menus.application !== ApplicationMenu.loggedOutOpen) {
            yield put(setApplicationMenu(ApplicationMenu.loggedOutOpen));
        } else if (pathname === '/closed' && menus.application !== ApplicationMenu.loggedOutClosed) {
            yield put(setApplicationMenu(ApplicationMenu.loggedOutClosed));
        }
        return;
    } else if (menus.application !== ApplicationMenu.dashboard) {
        yield put(setApplicationMenu(ApplicationMenu.dashboard));
    }
}

function* performRedirects() {
    const bootstrapped: RootState['app']['bootstrapped'] = yield select((state: RootState) => state.app.bootstrapped);
    const location: RootState['router']['location'] = yield select((state: RootState) => state.router.location);
    const search = location?.search ?? '';
    const pathname = location?.pathname;

    if (!bootstrapped || !pathname) {
        return;
    }

    const accessToken: RootState['auth']['accessToken'] = yield select((state: RootState) => state.auth.accessToken);

    const nonAuthenticatedRoutes = ['/login', '/closed', '/oauth2/callback', '/loggedout', '/account/open'];

    if (!accessToken && !nonAuthenticatedRoutes.includes(pathname ?? '')) {
        yield put(push({ pathname: '/login', search: `returnTo=${pathname}` }));
    } else if (accessToken && ['/', ...nonAuthenticatedRoutes].includes(pathname)) {
        // Redirect '/' to '/dashboard'
        yield put(replace('/dashboard'));
    } else if (!accessToken && pathname === '/login') {
        const returnTo = new URLSearchParams(search).get('returnTo') ?? '/dashboard';
        const oAuth2Service: SagaContext['oAuth2Client'] = yield getContext('oAuth2Client');
        oAuth2Service.startOauth({ returnTo });
    }
}

function* handleLogoutRoute(action: RouterActions) {
    if (action.type !== LOCATION_CHANGE) {
        return;
    }

    if (action.payload.location.pathname === '/logout') {
        yield call(onLogoutEvent);
    }
}

function* handleQuitRoute(action: RouterActions) {
    if (!APP_ELECTRON) {
        return;
    }

    if (action.type !== LOCATION_CHANGE) {
        return;
    }

    if (action.payload.location.pathname === '/quit') {
        const electronService: SagaContext['electronService'] = yield getContext('electronService');
        electronService?.quit();
    }
}

export function* navigationSagas() {
    yield all([
        takeEvery(
            [LOCATION_CHANGE, CALL_HISTORY_METHOD, 'access-token::set', 'access-token::clear'],
            updateNativeMenus,
        ),
        takeEvery(['bootstrap::complete', LOCATION_CHANGE], performRedirects),
        takeLatest(LOCATION_CHANGE, handleLogoutRoute),
        takeLatest(LOCATION_CHANGE, handleQuitRoute),
    ]);
}
