import { TypedContainerModule } from '@thinkalpha/common/ioc/TypedContainer.js';
import { initialize, type LDEvaluationDetail } from 'launchdarkly-js-client-sdk';
import type { IsomorphicBindings } from 'src/types/bindings';

export const launchDarklyBrowserModule = new TypedContainerModule<IsomorphicBindings>((bind) => {
    bind('LaunchDarklyClient').toDynamicValue((ctx) => {
        const configService = ctx.container.get('ConfigService');
        const datadogModule = ctx.container.get('DatadogService');
        const launchDarklyBrowserClient = initialize(
            configService.launchDarklyConfig.clientSideId,
            {
                kind: 'user',
                key: 'anonymous',
            },
            {
                inspectors: [
                    {
                        type: 'flag-used',
                        name: 'dd-inspector',
                        method: (key: string, detail: LDEvaluationDetail) => {
                            datadogModule.addFeatureFlagEvaluation(key, detail.value);
                        },
                    },
                ],
            },
        );

        return launchDarklyBrowserClient;
    });
});
