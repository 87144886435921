import { createSearchAlphaTargetString } from '../utils';
import { type TourGuideStep } from '@sjmc11/tourguidejs/src/types/TourGuideStep';

const searchAlphaTgSteps: TourGuideStep[] = [
    {
        title: 'Strategy Building',
        content:
            'Building a strategy starts here. Use the inputs to create queries using natural language or formulas.',
        target: createSearchAlphaTargetString('strategy-building'),
    },
    {
        title: 'Create a Query',
        content: 'Type out the condition you would like to query against in your natural language.',
        target: createSearchAlphaTargetString('query'),
    },
    {
        title: 'Send Query',
        content:
            'To process your natural language query, press the send button. A formula will be generated for your query. You can choose to accept, reject, or edit the formula.',
        target: createSearchAlphaTargetString('send'),
    },
    {
        title: 'Enabling/Disabling a Condition',
        content:
            'Turn a condition on or off to include it or exclude it from your search. This is useful if you want to keep a condition without deleting it.',
        target: createSearchAlphaTargetString('power'),
    },
    {
        title: 'Formula Mode',
        content: 'Toggle between ‘alpha’ mode and formula mode',
        target: createSearchAlphaTargetString('formula-mode'),
    },
    {
        title: 'Guided Mode',
        content:
            'Select this button to toggle guided mode on and off. Guided mode allows you to break down your conditions for more clarity, specifically in formula mode.',
        target: createSearchAlphaTargetString('guided-mode'),
    },
    {
        title: 'And/Or',
        content:
            'Select whether you would like all conditions to be met at once or at least one of the conditions to be met.',
        target: createSearchAlphaTargetString('and-or'),
    },
    {
        title: 'Add Query, Add Nested Query, or Load Query.',
        content:
            'Here you can choose to add another condition or a nested condition. Also, you can select “Load Saved Search” to load a previously saved search.',
        target: createSearchAlphaTargetString('add-nested-load'),
    },
    {
        title: 'Add New Group',
        content: 'Use condition groups for organizing more complex searches.',
        target: createSearchAlphaTargetString('new-group'),
    },
    {
        title: 'Error and Hazard Icons',
        content: ' Icons will be displayed if a condition is either incomplete or un-submitted.',
        target: createSearchAlphaTargetString('error-hazard'),
    },
];

const searchAlphaTargetOptions = [
    'strategy-building',
    'query',
    'formula-mode',
    'power',
    'guided-mode',
    'and-or',
    'send',
    'add-nested-load',
    'new-group',
    'error-hazard',
] as const;

export { searchAlphaTgSteps, searchAlphaTargetOptions };
