import type { SearchAlphaTourGuideTarget, TourGuideTarget } from './types';

// step target must include [data-tg-active="true"] so that the active widget container elements are targeted

const createTgTargetString = (target: TourGuideTarget): string => {
    return `[data-tg-target="${target}"][data-tg-active="true"]`;
};

export const createSearchAlphaTargetString = (target: SearchAlphaTourGuideTarget): string => {
    return createTgTargetString(target);
};
