import type { EventChannel } from 'redux-saga';
import { eventChannel } from 'redux-saga';
import { all, call, cancelled, delay, put, take, takeLeading } from 'redux-saga/effects';
import { container } from 'src/StaticContainer';
import { SocketEvents } from 'src/contracts/push';
import { pushServiceSocket } from 'src/lib/push';
import { appBecameStale, checkAppVersion, setCurrentAppVersion } from 'src/store/actions/app';
import { select } from 'src/store/effects';

const log = container.get('Logger').getSubLogger({ name: 'app-version' });

type SocketMessage = {
    type: 'run-version-check';
};

function listenForVersionCheckScenarios(socket: typeof pushServiceSocket) {
    log.info({ message: 'Creating listeners for update service' });

    // `eventChannel` takes a subscriber function
    // the subscriber function takes an `emit` argument to put messages onto the channel
    return eventChannel((emit: (input: SocketMessage) => void) => {
        const handleCheckForUpdate = () => {
            emit({ type: 'run-version-check' });
        };

        const handleVisibilityChanged = () => {
            if (document.visibilityState === 'visible') {
                handleCheckForUpdate();
            }
        };

        // Handle page visibility change:
        document.addEventListener('visibilitychange', handleVisibilityChanged);

        // Simple checks
        socket.on(SocketEvents.checkAppVersion, handleCheckForUpdate);

        // the subscriber must return an unsubscribe function
        // this will be invoked when the saga calls `channel.close` method
        const unsubscribe = () => {
            socket.off(SocketEvents.checkAppVersion, handleCheckForUpdate);
            document.removeEventListener('visibilitychange', handleVisibilityChanged);
        };

        return unsubscribe;
    });
}

function* listenToPush() {
    const channel: EventChannel<Record<string, never>> = yield call(listenForVersionCheckScenarios, pushServiceSocket);

    try {
        while (true) {
            const socketAction: SocketMessage = yield take(channel);

            if (socketAction.type === 'run-version-check') {
                log.info({ message: 'Server has informed us to check app version' });

                yield put(checkAppVersion());
            }
        }
    } catch (e) {
        if (e instanceof Error) {
            log.error({ message: 'Error from push service socket', error: e });
        } else {
            log.fatal({ message: 'Unknown exception', details: e });
        }
    } finally {
        const isCancelled: boolean = yield cancelled();
        if (isCancelled) {
            log.info({ message: 'Closing channel for app-version' });

            channel.close();
        }
    }
}

function* periodicallyPollVersion() {
    while (true) {
        yield put(checkAppVersion());

        // check every 10 minutes if nothing else has triggered a check
        yield delay(1000 * 60 * 10);
    }
}

function* handleVersionChecks() {
    const currentVersionText: string | undefined = yield select((state) => state.app.appVersion);
    const newVersionText: string = yield call(fetchVersionText);

    if (currentVersionText === undefined) {
        yield put(setCurrentAppVersion(newVersionText));
    } else {
        if (currentVersionText !== newVersionText) {
            yield put(setCurrentAppVersion(newVersionText));
            yield put(appBecameStale());
        }
    }

    // this delay is so that we can use takeLeading to throttle when we check.
    // so we'll check once every 5 minutes at most
    yield delay(1000 * 60 * 5);
}

export function* appUpdateSagas() {
    if (!APP_ELECTRON_MAIN) {
        yield all([listenToPush(), periodicallyPollVersion(), takeLeading('app-version::check', handleVersionChecks)]);
    }
}

function fetchVersionText() {
    return fetch(`/version.txt?q=${Date.now()}`).then((res) => res.text());
}
