import { adminSagas } from './admin';
import { appSagas } from './app';
import { askAlphaSagas } from './askAlpha';
import { authSagas } from './auth';
import { featureFlagsSagas } from './featureFlags';
import { intentVerificationSagas } from './intentVerification';
import { keyboardShortcutsSagas } from './keyboardShortcuts';
import { locatesPreferencesSagas } from './locates/locatePreferences';
import { locatesSagas } from './locates/locates';
import { locatesSocketSagas } from './locates/locatesSocket';
import { nativeSagas } from './native';
import { navigationSagas } from './navigation';
import { onboardingSagas } from './onboarding';
import { overlaysSagas } from './overlays';
import { queryClientSagas } from './query-client';
import { sessionSagas } from './session';
import { themeSagas } from './theme';
import { uiSagas } from './ui';
import { universeSagas } from './universe';
import { widgetConstructionSagas } from './widgets/construction';
import { linkingSagas } from './widgets/linking';
import { watchListSagas } from './widgets/watchList';
import { workspaceSagas } from './workspace';
import { all, spawn } from 'redux-saga/effects';

export function* rootSaga() {
    yield all([
        spawn(adminSagas),
        spawn(appSagas),
        spawn(askAlphaSagas),
        spawn(authSagas),
        spawn(widgetConstructionSagas),
        spawn(featureFlagsSagas),
        spawn(intentVerificationSagas),
        spawn(keyboardShortcutsSagas),
        spawn(locatesSagas),
        spawn(locatesPreferencesSagas),
        spawn(locatesSocketSagas),
        spawn(nativeSagas),
        spawn(navigationSagas),
        spawn(onboardingSagas),
        spawn(overlaysSagas),
        spawn(queryClientSagas),
        spawn(sessionSagas),
        spawn(themeSagas),
        spawn(uiSagas),
        spawn(universeSagas),
        spawn(workspaceSagas),
        spawn(watchListSagas),
        spawn(linkingSagas),
    ]);
}
